<template>
  <div>
    <el-card class="offerInfo mb5" shadow="never">
      <div slot="header" class="clearfix" style="display: flex; justify-content: flex-end">
        <span class="detail_title" style="margin-right: auto">Offer Info</span>

        <!-- <el-col :span="3">
          <div class="font-14 mb10">直发</div>
        </el-col> -->

        <el-button type="success" size="mini" @click="activeOffer()">active</el-button>
        <el-button type="warning" size="mini" @click="holdOffer()">hold</el-button>
        <el-button @click="updateTimedTaskClick()" size="mini">TimedTask</el-button>
        <el-button type="primary" size="mini" @click="updateAsyncModalClick()">Update</el-button>
      </div>
      <div>
        <el-row class="detail_row">
          <el-col :lg="3" :md="20">
            <p class="detail_item">
              offer id :
              <span>{{ offer.offerId }}</span>
            </p>
          </el-col>
          <el-col :lg="7" :md="20" :offset="1">
            <p class="detail_item">
              offer name :
              <span>{{ offer.offerName }}</span>
            </p>
          </el-col>
          <el-col :lg="5" :md="20" :offset="1">
            <p class="detail_item">
              status :
              <span>{{ offer.status }}</span>
            </p>
          </el-col>
          <el-col :lg="5" :md="20" :offset="1">
            <p class="detail_item">
              Control Type :
              <span>{{ offer.controlType }}</span>
            </p>
          </el-col>
        </el-row>
        <el-row class="detail_row">
          <el-col :lg="11" :md="20">
            <p class="detail_item">
              prod name :
              <span>{{ offer.prod }}</span>
            </p>
          </el-col>
          <el-col :lg="5" :md="20" :offset="1">
            <p class="detail_item">
              Async Click:
              <span>{{ offer.async }}</span>
            </p>
          </el-col>
        </el-row>
        <el-row class="detail_row" :gutter="10">
          <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
            <p class="detail_item">
              Timed Task Status:
              <span>{{ offer.openStatus }}</span>
            </p>
          </el-col>
          <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
            <p class="detail_item">
              Open Time :
              <span>{{ offer.openTime }}</span>
            </p>
          </el-col>
          <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
            <p class="detail_item">
              Close Time :
              <span>{{ offer.closeTime }}</span>
            </p>
          </el-col>
          <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
            <p class="detail_item">
              important :
              <span>{{ offer.important }}</span>
            </p>
          </el-col>
        </el-row>
      </div>
    </el-card>
    <el-card class="sourceInfo mb5" shadow="never">
      <div slot="header" class="clearfix">
        <span class="detail_title">Source Info</span>
        <el-button type="primary" class="detail_button" size="mini" @click="updateSourceModalClick"
          >Update</el-button
        >
      </div>
      <div>
        <el-row class="detail_row">
          <el-col :lg="11" :md="20">
            <p class="detail_item">
              source id :
              <span>{{ offer.sourceId }}</span>
            </p>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <p class="detail_item">
              source offer id :
              <span>{{ offer.sourceOfferId }}</span>
            </p>
          </el-col>
        </el-row>
        <el-row class="detail_row">
          <el-col :lg="11" :md="20">
            <p class="detail_item">
              source tracking link
              <el-popover placement="top-start" title="参数" width="200" trigger="hover">
                <p>{transaction_id}</p>
                <p>{aff}</p>
                <p>{affiliate_id}</p>
                <p>{real_aff}</p>
                <p>{affiliate_sub_id}</p>
                <p>{android_id}</p>
                <p>{gaid}</p>
                <p>{idfa}</p>
                <p>{country}</p>
                <p>{ip}</p>
                <p>{clickTime}</p>
                <p>{os_version}</p>
                <p>{appname}</p>
                <p>{hour}</p>
                <p>{pkg}</p>
                <p>{ua}</p>
                <p>{lang}</p>
                <p>{offer_id}</p>
                <p>{af_adset_id}</p>
                <p>{media_type}</p>
                <p>{ad_type}</p>
                <p>{model}</p>
                <p>{date}</p>
                <p>{os}</p>
                <p>{offer_name}</p>
                <p>{brand}</p>
                <p>{b_size}</p>
                <p>{idfv}</p>
                <p>{af_sub2}</p>
                <i class="el-icon-warning-outline" slot="reference"></i>
              </el-popover>
              :
              <span>{{ offer.sourceTrackingLink }}</span>
            </p>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <p class="detail_item">
              tracking link :
              <span>{{ offer.trackingLink }}</span>
            </p>
          </el-col>
        </el-row>
        <el-row class="detail_row">
          <el-col :lg="11" :md="20">
            <p class="detail_item">
              settle event :
              <span>{{ offer.settleEvent }}</span>
            </p>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <p class="detail_item">
              campaign name :
              <span>{{ offer.campaignName }}</span>
            </p>
          </el-col>
        </el-row>
        <el-row class="detail_row">
          <el-col :lg="11" :md="20">
            <p class="detail_item">
              campaign cap :
              <span>{{ offer.campaignCap }}</span>
            </p>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <p class="detail_item">
              campaign status :
              <span>{{ offer.campaignStatus }}</span>
            </p>
          </el-col>
        </el-row>
        <el-row class="detail_row">
          <el-col :lg="7" :md="7">
            <p class="detail_item">
              impression tracking :
              <span>{{ offer.impressionTracking }}</span>
            </p>
          </el-col>
          <el-col :lg="7" :md="7" :offset="1">
            <p class="detail_item">
              impression open :
              <span>{{ offer.impressionOpen === 1 ? 'open' : 'close' }}</span>
            </p>
          </el-col>
          <el-col :lg="6" :md="6" :offset="1">
            <p class="detail_item">
              impression ratio :
              <span>{{ offer.impressionRatio }}</span>
            </p>
          </el-col>
          <el-col :lg="2" :md="2" align="right">
            <p class="detail_item"
              ><el-button
                type="primary"
                size="mini"
                @click="updateTmpressionClick"
                style="margin-left: 10px"
                >Update</el-button
              ></p
            >
          </el-col>
        </el-row>
        <el-row style="margin-top: 20px">
          <el-col :lg="18" :md="20">
            <p class="detail_item" style="display: flex; align-items: center;">
              <!-- 左侧部分 -->
              <span style="flex: 1;">
                modify the source tracking link
                <el-input
                  v-model="sourceTrackinglink"
                  :disabled="offer.controlType === 'api'"
                  placeholder="source tracking link"
                  style="width: 320px"
                  size="mini"
                ></el-input>
                <el-button
                  type="primary"
                  size="mini"
                  @click="updateTrackinglinkClick"
                  style="margin-left: 10px"
                  >Update</el-button
                >
              </span>
              <!-- 右侧部分 -->
              <span style="display: flex; align-items: center; margin-left: auto;">
                <div class="font-14 mb10" style="margin-right: 10px;">是否开启直送AF</div>
                <el-switch
                  v-model="offer.isDirectLink"
                  @change="updateDirectLinkStatus"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  active-value="yes"
                  inactive-value="no"
                ></el-switch>
              </span>
              <span style="width: 40px;"></span>
              <span style="display: flex; align-items: center; margin-left: auto;">
                <div class="font-14 mb10" style="margin-right: 10px;">是否开启advancedProxy</div>
                <el-switch
                  v-model="offer.advancedProxy"
                  @change="updateadvancedProxy"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  active-value="yes"
                  inactive-value="no"
                ></el-switch>
              </span>
            </p>
          </el-col>
        </el-row>
      </div>
    </el-card>
    <el-card class="payoutInfo mb5" shadow="never">
      <div slot="header" class="clearfix">
        <span class="detail_title">Payout Info</span>
        <el-button type="primary" size="mini" class="detail_button" @click="updatePayoutModalClick"
          >Update</el-button
        >
      </div>
      <div>
        <el-row class="detail_row">
          <el-col :lg="5" :md="5">
            <p class="detail_item">
              Event Name :
              <span>install</span>
            </p>
          </el-col>
          <el-col :lg="5" :md="5">
            <p class="detail_item">
              Revenue :
              <span>{{ offer.revenue }}</span>
            </p>
          </el-col>
          <el-col :lg="5" :md="5">
            <p class="detail_item">
              Payout :
              <span>{{ offer.payout }}</span>
            </p>
          </el-col>
          <el-col :lg="5" :md="5">
            <p class="detail_item">
              Install Cap Daily :
              <span>{{ offer.capDaily }}</span>
            </p>
          </el-col>
          <el-col :lg="4" :md="4">
            <p class="detail_item">
              Click Cap Daily :
              <span>{{ offer.clickCapDaily }}</span>
            </p>
          </el-col>

          <!-- <el-col :lg="2" :md="2">
            <p class="detail_item">
              currency :
              <span>{{ offer.currency }}</span>
            </p>
          </el-col>
          <el-col :lg="3" :md="3">
            <p class="detail_item">
              payout type :
              <span>{{ offer.payoutType }}</span>
            </p>
          </el-col>

          <el-col :lg="3" :md="3">
            <p class="detail_item">
              daily revenue :
              <span>{{ offer.dailyRevenue }}</span>
            </p>
          </el-col>

          <el-col :lg="3" :md="3">
            <p class="detail_item">
              allow deduct :
              <span v-if="offer.allowDeduct == 1">Avaliable</span>
              <span v-else>Disabling</span>
            </p>
          </el-col> -->
        </el-row>
        <el-row class="detail_row mb10" v-for="(item, index) in eventCapDaily" :key="index">
          <el-col :lg="5" :md="5">
            <p class="detail_item">
              Event Name :
              <span>{{ item.eventName }}</span>
            </p>
          </el-col>
          <el-col :lg="5" :md="5">
            <p class="detail_item">
              Revenue :
              <span>{{ item.revenue }}</span>
            </p>
          </el-col>
          <el-col :lg="5" :md="5">
            <p class="detail_item">
              Payout :
              <span>{{ item.payout }}</span>
            </p>
          </el-col>
          <el-col :lg="5" :md="5">
            <p class="detail_item">
              Event Cap Daily :
              <span>{{ item.capDaily }}</span>
            </p>
          </el-col>
          <!-- <el-col :lg="4" :md="4">
            <p class="detail_item">
              currency :
              <span>{{ item.currency }}</span>
            </p>
          </el-col> -->
        </el-row>
      </div>
    </el-card>
    <!-- Enabled Affiliates -->
    <el-card class="offerAffiliateBlacklist" shadow="never">
      <div slot="header" class="clearfix">
        <span class="detail_title">Enabled Affiliates</span>
      </div>
      <enabled-affiliates
        :offerId="offerId"
        :dataList="eventCapDaily"
        :affiliatesGroupList="affiliatesGroupList"
        :affiliateLoading="affiliateLoading"
        ref="enabledAffiliates"
      />
    </el-card>
    <!-- Enabled Affiliates End -->
    <el-card class="settingInfo mb5" shadow="never">
      <div slot="header" class="clearfix">
        <span class="detail_title">Setting Info</span>
        <el-button type="primary" class="detail_button" size="mini" @click="updateSettingModalClick"
          >Update</el-button
        >
      </div>
      <div>
        <el-row class="detail_row">
          <el-col :lg="11" :md="20">
            <p class="detail_item">
              platforms :
              <span>{{ offer.platforms }}</span>
            </p>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <p class="detail_item">
              countries :
              <el-tooltip>
                <div slot="content" style="max-width: 500px">{{ offer.countries }}</div>
                <span
                  class="line-overflow"
                  style="cursor: pointer"
                  v-clipboard:copy="offer.countries"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onCopyError"
                >
                  {{ offer.countries }}
                </span>
              </el-tooltip>
            </p>
          </el-col>
        </el-row>
        <el-row class="detail_row">
          <el-col :lg="11" :md="20">
            <p class="detail_item">
              regions :
              <span>{{ offer.regions }}</span>
            </p>
          </el-col>
          <el-col :lg="11" :md="20" :offset="1">
            <p class="detail_item">
              cities :
              <span>{{ offer.citys }}</span>
            </p>
          </el-col>
        </el-row>
        <el-row class="detail_row">
          <el-col :lg="11" :md="20">
            <p class="detail_item">
              download type :
              <span>{{ offer.downloadType }}</span>
            </p>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <p class="detail_item">
              gid idfa needs :
              <span>{{ offer.gidIdfaNeeds }}</span>
            </p>
          </el-col>
        </el-row>
        <el-row class="detail_row">
          <el-col :lg="11" :md="20">
            <p class="detail_item">
              offer whitelist limited :
              <span>{{ offer.whitelistLimited }}</span>
            </p>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <p class="detail_item">
              carriers :
              <span>{{ offer.carriers }}</span>
            </p>
          </el-col>
        </el-row>
        <el-row class="detail_row">
          <el-col :lg="11" :md="20">
            <p class="detail_item">
              reject open :
              <span>{{ offer.rejectOpen }}</span>
            </p>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <p class="detail_item">
              reject rate :
              <span>{{ offer.rejectRate }}</span>
            </p>
          </el-col>
        </el-row>
        <el-row class="detail_row">
          <el-col :lg="11" :md="20">
            <p class="detail_item">
              Traffic Tag :
              <span>{{ offer.trafficTag }}</span>
            </p>
          </el-col>
        </el-row>
      </div>
    </el-card>
    <el-card class="Description mb5" shadow="never">
      <div slot="header" class="clearfix">
        <span class="detail_title">Description Info</span>
        <el-button
          type="primary"
          class="detail_button"
          size="mini"
          :disabled="offer.controlType === 'api'"
          @click="updatedescModalClick"
          >Update</el-button
        >
      </div>
      <div>
        <el-row class="detail_row">
          <el-col :lg="11" :md="20">
            <p class="detail_item">
              preview link :
              <span>{{ offer.previewLink }}</span>
            </p>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <p class="detail_item">
              offer category :
              <span>{{ offer.offerCategory }}</span>
            </p>
          </el-col>
        </el-row>
        <el-row class="detail_row">
          <el-col :lg="11" :md="20">
            <p class="detail_item">
              description:
              <span>{{ offer.description }}</span>
            </p>
          </el-col>

          <el-col :lg="12" :md="20" :offset="1">
            <p class="detail_item">
              short description :
              <span>{{ offer.shortDescription }}</span>
            </p>
          </el-col>
        </el-row>
        <el-row class="detail_row">
          <el-col :lg="11" :md="20">
            <p class="detail_item">
              traffic allowed :
              <span>{{ offer.trafficAllowed }}</span>
            </p>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <p class="detail_item">
              traffic forbidden :
              <span>{{ offer.trafficForbidden }}</span>
            </p>
          </el-col>
        </el-row>
        <el-row class="detail_row">
          <el-col :lg="11" :md="20">
            <p class="detail_item">
              conversion flow:
              <span>{{ offer.conversionFlow }}</span>
            </p>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <p class="detail_item">
              incentive :
              <span>{{ offer.incentive }}</span>
            </p>
          </el-col>
        </el-row>
        <el-row class="detail_row">
          <el-col :lg="11" :md="20">
            <p class="detail_item">
              osMinVersion :
              <span>{{ offer.osMinVersion }}</span>
            </p>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <p class="detail_item">
              classifyType :
              <span>{{ offer.classifyType }}</span>
            </p>
          </el-col>
        </el-row>
      </div>
    </el-card>
    <el-card class="mb5" shadow="never">
      <div>
        <el-row class="detail_row">
          <!--          <el-col :lg="10" :md="10">-->
          <!--            <p class="detail_item">-->
          <!--              offer manager :-->
          <!--              <span>{{ offer.manager }}</span>-->
          <!--            </p>-->
          <!--          </el-col>-->
          <el-col :lg="10" :md="10">
            <p class="detail_item">
              sub rule :
              <span>{{ offer.ruleName }}</span>
            </p>
          </el-col>
          <el-col :span="4" align="right">
            <el-button type="primary" size="mini" @click="updateManagerAndSubRule"
              >Update</el-button
            >
          </el-col>
        </el-row>
      </div>
    </el-card>
    <el-card class="Description" shadow="never">
      <div slot="header" class="clearfix">
        <span class="detail_title">Cretivies Info</span>
      </div>
      <div v-loading="cretiviesInfoLoading" element-loading-text="图片上传中....">
        <!-- 图片上传 -->
        <el-upload
          action="/api/creatives/saveImages"
          list-type="picture-card"
          :show-file-list="false"
          :multiple="true"
          :before-upload="beforeUpload"
          :on-success="uploadSuccess"
          :on-remove="handleRemove"
          :before-remove="beforeRemove"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-table :data="creativiesInfo" style="width: 100%">
          <el-table-column label="图片" width="180">
            <template slot-scope="scope">
              <img :src="scope.row.creativeUrl" alt="" style="width: 100%" />
            </template>
          </el-table-column>
          <el-table-column prop="size" label="尺寸" width="180">
            <template slot-scope="scope">
              {{ scope.row.size }} <i class="el-icon-edit" @click="editSize(scope.row)"></i>
            </template>
          </el-table-column>
          <el-table-column prop="creativeUrl" label="链接"> </el-table-column>
          <el-table-column label="Remove">
            <template slot-scope="scope">
              <el-button type="text" @click="beforeRemove(scope.row)">Remove</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>

    <!-- 定时任务 模块框 -->
    <el-dialog
      :visible.sync="timedTaskVisible"
      title="update Time Task"
      :close-on-click-modal="false"
      :append-to-body="true"
      width="600px"
    >
      <el-form :model="timedTask" label-position="right">
        <el-row type="flex" justify="center">
          <el-col :lg="15" :md="20">
            <el-form-item label="Status" label-width="100px">
              <el-switch
                v-model="timedTask.status"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :active-value="true"
                :inactive-value="false"
              ></el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" justify="center">
          <el-col :lg="15" :md="20">
            <el-form-item label="Open Time" label-width="100px">
              <el-time-select
                placeholder="Open Time"
                v-model="timedTask.openTime"
                :picker-options="{
                  start: '00:00',
                  step: '00:15',
                  end: '23:59',
                }"
              ></el-time-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" justify="center">
          <el-col :lg="15" :md="20">
            <el-form-item label="Close Time" label-width="100px">
              <el-time-select
                placeholder="Close Time"
                v-model="timedTask.closeTime"
                :picker-options="{
                  start: '00:00',
                  step: '00:15',
                  end: '23:59',
                  minTime: timedTask.openTime,
                }"
              ></el-time-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" justify="center">
          <p :style="{ fontSize: '12px', color: 'red', lineHeight: '14px', marginTop: '10px' }">
            Time Task is 0 TimeZone The difference between the opening and closing time must be
            greater than 15 minutes
          </p>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="timedTaskVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateTimedTask">确 定</el-button>
      </span>
    </el-dialog>
    <!-- payout 模块框 -->
    <el-dialog
      :visible.sync="payoutVisible"
      title="update Payout Info"
      :close-on-click-modal="false"
      :append-to-body="true"
      width="70%"
    >
      <el-form
        :model="payoutInfo"
        ref="payoutInfo"
        label-position="top"
        :rules="payoutInfoRules"
        size="mini"
      >
        <el-row :gutter="10">
          <el-col :lg="5" :md="5">
            <el-form-item label="Event name">
              <el-input
                disabled
                v-model.trim="payoutInfo.eventName"
                placeholder="please add Event name..."
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="5" :md="5">
            <el-form-item label="Revenue" prop="revenue">
              <el-input
                :disabled="offer.controlType === 'api'"
                v-model.trim="payoutInfo.revenue"
                placeholder="please add revenue..."
                @keyup.native="UpNumber"
                @keydown.native="UpNumber"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="5" :md="5">
            <el-form-item label="Payout" prop="payout">
              <el-input
                v-model.trim="payoutInfo.payout"
                placeholder="please add payout..."
                @keyup.native="UpNumber"
                @keydown.native="UpNumber"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="5" :md="5">
            <el-form-item label="Install Cap Daily">
              <el-input
                :disabled="offer.controlType === 'api'"
                v-model.number="payoutInfo.capDaily"
                placeholder="please add cap daily..."
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :lg="3" :md="3">
            <el-form-item label="Click Cap Daily" label-width="120px">
              <el-input
                v-model.number="payoutInfo.clickCapDaily"
                placeholder="please add manual click cap daily..."
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :lg="3" :md="3">
            <el-form-item label="Allow Deduct" label-width="100px">
              <el-select v-model="payoutInfo.allowDeduct" disabled>
                <el-option
                  v-for="item in deductOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
          <!-- <el-col :lg="3" :md="3">
            <el-form-item label="Click Cap Incr Fix" label-width="120px"
              ><el-input v-model="payoutInfo.clickCapIncrFix"></el-input
            ></el-form-item>
          </el-col> -->
          <el-col :lg="1" :md="1">
            <el-form-item label="." label-width="120px">
              <el-button
                type="primary"
                icon="el-icon-plus"
                circle
                size="mini"
                @click="addPayoutInfoItem()"
              ></el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-form
        :model="tempEventCapDailyArr"
        ref="tempEventCapDailyArr"
        label-position="top"
        size="mini"
      >
        <el-row
          :gutter="10"
          v-for="(item, index) in tempEventCapDailyArr.tempEventCapDaily"
          :key="index"
        >
          <el-col :lg="5" :md="5">
            <el-form-item
              label="Event name"
              :prop="'tempEventCapDaily.' + index + '.eventName'"
              :rules="[{ required: true, message: 'please add eventName', trigger: 'blur' }]"
            >
              <el-input
                v-model="item.eventName"
                @blur="blurFun(index, 'tempEventCapDailyArr.tempEventCapDaily', 'eventName')"
                placeholder="please add cap eventName..."
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="5" :md="5">
            <el-form-item
              label="Revenue"
              label-width="100px"
              :prop="'tempEventCapDaily.' + index + '.revenue'"
              :rules="[{ required: true, message: 'please add revenue', trigger: 'blur' }]"
            >
              <el-input
                v-model="item.revenue"
                placeholder="please add revenue..."
                @keyup.native="UpNumber"
                @keydown.native="UpNumber"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="5" :md="5">
            <el-form-item
              label="payout"
              :prop="'tempEventCapDaily.' + index + '.payout'"
              :rules="[{ required: true, message: 'please add payout', trigger: 'blur' }]"
            >
              <el-input
                v-model="item.payout"
                placeholder="please add payout..."
                @keyup.native="UpNumber"
                @keydown.native="UpNumber"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="5" :md="5">
            <el-form-item label="Event cap daily"
              ><el-input v-model="item.capDaily" placeholder="please add capDaily..."></el-input
            ></el-form-item>
          </el-col>
          <!-- <el-col :lg="2" :md="2">
            <el-form-item label="currency "
              ><el-input
                v-model="item.currency"
                disabled
                placeholder="please add currency..."
              ></el-input
            ></el-form-item>
          </el-col> -->
          <el-col :lg="4" :md="4">
            <el-form-item label="." label-width="120px">
              <el-button
                type="danger"
                icon="el-icon-minus"
                circle
                size="mini"
                @click="delPayoutInfoItem(index)"
              ></el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="payoutVisible = false">取 消</el-button>
        <el-button type="primary" @click="updatePayoutClick('payoutInfo', 'tempEventCapDailyArr')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- description模块框 -->
    <el-dialog
      :visible.sync="descriptionVisible"
      title="Update Description Info"
      :close-on-click-modal="false"
      :append-to-body="true"
    >
      <el-form :model="descriptionInfo" label-position="left">
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="ClassifyType" label-width="100px">
              <el-select v-model="descriptionInfo.classifyType">
                <el-option
                  v-for="item in classifyTypeOption"
                  :key="item.value"
                  :value="item.value"
                  :label="item.label"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <el-form-item label="Description" label-width="100px">
              <el-input
                v-model="descriptionInfo.description"
                placeholder="please add description..."
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="Short Description" label-width="130px">
              <el-input
                v-model="descriptionInfo.shortDescription"
                placeholder="please add short description..."
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <el-form-item label="Preview link" label-width="100px">
              <el-input
                v-model="descriptionInfo.previewLink"
                placeholder="please add previewLink..."
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="Category" label-width="100px">
              <el-input
                v-model="descriptionInfo.category"
                placeholder="please add category..."
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <el-form-item label="Traffic Allowed" label-width="120px">
              <el-input
                v-model="descriptionInfo.trafficAllowed"
                placeholder="please add traffic allowed..."
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item>
            <el-col :lg="11" :md="20">
              <el-form-item label="Traffic Forbidden" label-width="130px">
                <el-input
                  v-model="descriptionInfo.trafficForbidden"
                  placeholder="please add trafficForbidden..."
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :lg="12" :md="20" :offset="1">
              <el-form-item label="Concersion Flow" label-width="130px">
                <el-input
                  v-model="descriptionInfo.conversionFlow"
                  placeholder="please add conversionFlow..."
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="descriptionVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateDescClick">确 定</el-button>
      </span>
    </el-dialog>

    <!-- async模块框 -->
    <el-dialog
      :visible.sync="settingasyncVisible"
      title="update Async Info"
      :close-on-click-modal="false"
      :append-to-body="true"
    >
      <el-form :model="asyncSetting" label-position="left">
        <el-row :gutter="10">
          <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
            <el-form-item label="offerName" label-width="90px"
              ><el-input v-model="asyncSetting.offerName" :disabled="offer.controlType === 'api'"
            /></el-form-item>
          </el-col>
          <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
            <el-form-item label="important" label-width="80px">
              <el-select v-model="asyncSetting.important">
                <el-option
                  v-for="item in importantOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
            <el-form-item label="controlType" label-width="100px">
              <el-select v-model="asyncSetting.controlType" :disabled="offer.controlType === 'api'">
                <el-option
                  v-for="item in controlTypeOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="Async Click" label-width="100px">
              <el-switch
                v-model="asyncSetting.async"
                active-text="Yes"
                inactive-text="No"
                active-color="#13ce66"
                inactive-color="#ff4949"
                active-value="TRUE"
                inactive-value="FALSE"
                @change="asyncChangeHandle"
              ></el-switch>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="settingasyncVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateAsyncInfoClick">确 定</el-button>
      </span>
    </el-dialog>
    <!-- setting模块框 -->
    <el-dialog
      :visible.sync="settingVisible"
      title="update Setting Info"
      :close-on-click-modal="false"
      :append-to-body="true"
    >
      <el-form :model="settingInfo" label-position="left" label-width="100px">
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="Platform">
              <el-select v-model="settingInfo.platforms">
                <el-option
                  v-for="item in platformOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <el-form-item label="Download Type" label-width="120px">
              <el-select v-model="settingInfo.downloadType">
                <el-option
                  v-for="item in downloadTypeOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="Countries"
              ><el-input
                v-model="settingInfo.countries"
                placeholder="please add countries..."
              ></el-input
            ></el-form-item>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <el-form-item label="Citys"
              ><el-input v-model="settingInfo.citys" placeholder="please add citys..."></el-input
            ></el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="Carriers"
              ><el-input
                v-model="settingInfo.carriers"
                placeholder="please add carriers..."
              ></el-input
            ></el-form-item>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <el-form-item label="Regions"
              ><el-input
                v-model="settingInfo.regions"
                placeholder="please add regions..."
              ></el-input
            ></el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="Traffic Type">
              <el-select v-model="settingInfo.trafficTag" multiple filterable clearable>
                <el-option
                  v-for="item in trafficTagList"
                  :key="item.key"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="settingVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateSettingClick">确 定</el-button>
      </span>
    </el-dialog>

    <!-- source Info模态框 -->
    <el-dialog
      :visible.sync="sourceInfoVisible"
      title="update Source Info"
      :close-on-click-modal="false"
      :append-to-body="true"
      width="600px"
      @close="sourceModalCancel"
    >
      <el-form
        ref="sourceInfoForm"
        :model="sourceInfo"
        label-position="right"
        :rules="sourceRules"
        label-width="129px"
      >
        <el-row type="flex" justify="center">
          <el-col :lg="15" :md="20">
            <el-form-item label="Campaign Name" prop="campaignName"
              ><el-input v-model="sourceInfo.campaignName"></el-input
            ></el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" justify="center">
          <el-col :lg="15" :md="20">
            <el-form-item label="Cap" prop="cap"
              ><el-input v-model.number="sourceInfo.cap"></el-input
            ></el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" justify="center">
          <el-col :lg="15" :md="20">
            <el-form-item label="Status">
              <el-switch
                v-model="sourceInfo.status"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :active-value="true"
                :inactive-value="false"
              ></el-switch>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="sourceInfoVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateSourceInfoClick">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="impressionVisible"
      title="update Source Info"
      :close-on-click-modal="false"
      :append-to-body="true"
      width="600px"
      @close="impressionModalCancel"
    >
      <el-form
        ref="impressionForm"
        :model="impressionInfo"
        label-position="right"
        :rules="impressionInfo.impressionOpen === 1 ? impressionRules : impressionRules2"
        label-width="200px"
      >
        <el-form-item
          label="impression Tracking"
          prop="impressionTracking"
          :required="impressionInfo.impressionOpen === 1 ? true : false"
          ><el-input v-model="impressionInfo.impressionTracking"></el-input
        ></el-form-item>
        <el-form-item label="impression Open">
          <el-switch
            v-model="impressionInfo.impressionOpen"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-value="1"
            :inactive-value="0"
          ></el-switch>
        </el-form-item>
        <el-form-item
          label="impression ratio"
          prop="impressionRatio"
          :required="impressionInfo.impressionOpen === 1 ? true : false"
        >
          <el-input
            :disabled="!impressionInfo.impressionOpen"
            v-model="impressionInfo.impressionRatio"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="impressionVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateImpressionClick('impressionInfo')">确 定</el-button>
      </span>
    </el-dialog>
    <!-- // 编辑offer manager 和 sub rule -->
    <el-dialog
      :visible.sync="upManagerAndSubRuleVisible"
      title="update SubRule"
      :close-on-click-modal="false"
      :append-to-body="true"
      width="600px"
    >
      <el-form
        ref="upManagerAndSubRuleForm"
        :model="upManagerAndSubRule"
        label-position="right"
        :rules="sourceRules"
        label-width="129px"
      >
        <el-row type="flex" justify="center">
          <el-col :lg="15" :md="20">
            <!--            <el-form-item label="offer manager" prop="">
              <el-select
                v-model="upManagerAndSubRule.accountId"
                placeholder="Please select offer manager"
                filterable
              >
                <el-option
                  v-for="item in option.manager"
                  :key="item.accountId"
                  :label="item.username"
                  :value="item.accountId"
                ></el-option>
              </el-select>
            </el-form-item>-->
            <el-form-item label="sub rule" prop="">
              <el-select
                v-model="upManagerAndSubRule.ruleId"
                placeholder="Please select sub rule"
                filterable
              >
                <el-option
                  v-for="item in option.rule"
                  :key="item.ruleId"
                  :label="item.ruleName"
                  :value="item.ruleId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="upManagerAndSubRuleVisible = false">取 消</el-button>
        <el-button type="primary" @click="upManagerAndSubRuleClick">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import {
    getOfferDetail,
    updateOfferSource,
    updateOfferPayout,
    updateOfferDescription,
    updateOfferSetting,
    updateOfferAsyncInfo,
    updateOfferTimedTask,
    changeOfferCampaignName,
    updateImpression,
    updateOfferManagerRule,
    handleUpdateSize,
    updateOfferIsDirectLink,
    updateOfferAdvancedProxy,
  } from 'api/offer/detail';
  import { mapState } from 'vuex';
  import {
    fetchCreativesList,
    handleCreativesAddBatch,
    handleCreativesDelete,
  } from 'api/product/add';
  import { getReportSubruleList } from 'api/product/subrule';
  import { getAllTags } from 'api/affiliate/tag';
  import { getPermissionAllList } from 'api/account/permission';
  import { filterObject } from '@/utils/utils';
  import { cloneDeep } from 'lodash-es';
  import { ValiNumberAndFloat } from '@/utils/validate';
  import EnabledAffiliates from './EnabledAffiliates';
  import { batchHold, batchActive } from 'api/product/rankSearch';
  export default {
    components: {
      EnabledAffiliates,
    },
    props: {
      offerId: {
        type: [String, Number],
        required: true,
      },
    },
    data() {
      return {
        offer: {},
        descriptionVisible: false,
        descriptionInfo: {
          offerId: this.offerId,
          description: '',
          shortDescription: '',
          previewLink: '',
          category: '',
          trafficAllowed: '',
          trafficForbidden: '',
          conversionFlow: '',
          classifyType: '',
        },
        creativiesInfo: [],
        cretiviesInfoLoading: false,
        loading: false,
        classifyTypeOption: [
          {
            label: '',
            value: '',
          },
          {
            label: 'adult',
            value: 'adult',
          },
          {
            label: 'mainstream',
            value: 'mainstream',
          },
        ],
        importantOption: [
          {
            label: 'important',
            value: 'important',
          },
          {
            label: 'unimportant',
            value: 'unimportant',
          },
        ],
        carriersGroup: [],
        payoutVisible: false,
        payoutInfo: {
          offerId: this.$route.query.offerId,
          revenue: '',
          capDaily: '',
          payout: '',
          manualCapDaily: '',
          dailyRevenue: '',
          clickCapDaily: '',
          allowDeduct: '',
          clickCapIncrFix: '',
          eventName: 'install',
        },
        eventCapDaily: [],
        // 这里需要定义一个对象，form表单校验无法使用object
        tempEventCapDailyArr: {
          tempEventCapDaily: [],
        },
        controlTypeOption: [
          {
            label: 'private',
            value: 'private',
          },
          {
            label: 'public',
            value: 'public',
          },
        ],
        deductOption: [
          {
            label: 'Disabling',
            value: 0,
          },
          {
            label: 'Available',
            value: 1,
          },
        ],
        settingInfo: {
          offerId: this.$route.query.offerId,
          platforms: null,
          countries: null,
          citys: null,
          carriers: null,
          regions: null,
          downloadType: null,
          trafficTag: null,
        },
        settingasyncVisible: false,
        asyncSetting: {
          offerName: '',
          controlType: '',
          important: '',
          async: null,
        },
        sourceTrackinglink: '',
        settleEventValue: '',
        capSetting: {},
        clickDailyCap: null,
        mixClickOption: [
          {
            label: 'CLOSE',
            value: '0',
          },
          {
            label: 'OPEN',
            value: '1',
          },
        ],
        downloadTypeOption: [
          {
            label: 'GP',
            value: 'GP',
          },
          {
            label: 'ITUNES',
            value: 'ITUNES',
          },
          {
            label: 'OTHERS',
            value: 'OTHERS',
          },
        ],
        platformOption: [
          {
            label: 'ANDROID',
            value: 'ANDROID',
          },
          {
            label: 'IOS',
            value: 'IOS',
          },
          {
            label: 'PC',
            value: 'PC',
          },
          {
            label: 'ALL',
            value: 'ALL',
          },
        ],
        settingVisible: false,
        timedTask: {
          offerId: '',
          status: false,
          openTime: '',
          closeTime: '',
        },
        timedTaskVisible: false,
        impressionVisible: false,
        upManagerAndSubRule: {
          accountId: '',
          ruleId: '',
        },
        upManagerAndSubRuleVisible: false,
        impressionInfo: {
          impressionTracking: '',
          impressionOpen: 0,
          impressionRatio: 0,
        },
        trafficTagList: [],
        sourceInfoVisible: false,
        sourceInfo: {},
        option: {
          manager: [],
          rule: [],
        },
        sourceRules: {
          campaignName: [
            {
              required: true,
              trigger: ['blur', 'change'],
              message: 'Campaign Name不能为空',
            },
          ],
          cap: [
            {
              required: true,
              trigger: ['blur', 'change'],
              message: 'Cap不能为空',
            },
            { type: 'number', message: 'Cap必须为数值' },
          ],
        },
        payoutInfoRules: {
          revenue: [{ required: true, message: 'please add revenue', trigger: 'blur' }],
          payout: [{ required: true, message: 'please add payout', trigger: 'blur' }],
        },
        impressionRules2: {},
        impressionRules: {
          impressionTracking: [
            { required: true, message: 'please add impression tracking', trigger: 'blur' },
          ],
          impressionRatio: [
            { required: true, message: 'please add impression Ratio', trigger: 'blur' },
            { validator: ValiNumberAndFloat, trigger: ['blur', 'change'] },
          ],
        },
      };
    },
    computed: {
      ...mapState('affiliate', {
        affiliatesGroupList: (state) => state.affiliatesGroupList,
        affiliateLoading: (state) => state.affiliateLoading,
      }),
    },
    mounted() {
      this.init();
    },
    methods: {
      init() {
        this.getOfferDetail();
        this.subruleList();
        this.managerList();
        this.getImgsList();
        if (this.$refs.enabledAffiliates && this.$refs.enabledAffiliates.getList) {
          this.$refs.enabledAffiliates.getList();
        }
      },
      editSize(row) {
        console.log(row);
        this.$prompt('请输入尺寸', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPlaceholder: '请输入尺寸 xx*xx',
        })
          .then(({ value }) => {
            if (!value) {
              this.$message.error('请输入尺寸！');
            } else {
              handleUpdateSize({ id: row.id, size: value }).then((res) => {
                console.log(res);
                if (res.code === 200) {
                  this.getImgsList();
                  this.$message.success('尺寸更新成功！');
                } else {
                  this.$message.error(res.message);
                }
              });
            }
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '取消输入',
            });
          });
      },
      activeOffer() {
        let param = {
          offerIds: [this.offerId],
        };
        batchActive(param).then((response) => {
          if (response.success === true) {
            this.$message({
              message: 'avtice Success',
              type: 'success',
            });
            this.getOfferDetail();
          } else {
            this.$message.error('Error' + response.message);
          }
        });
      },
      holdOffer() {
        let param = {
          offerIds: [this.offerId],
        };
        batchHold(param).then((response) => {
          if (response.success === true) {
            this.$message({
              message: 'hold Success',
              type: 'success',
            });
            this.getOfferDetail();
          } else {
            this.$message.error('Error' + response.message);
          }
        });
      },
      UpNumber(e) {
        // 输入框中只允许输入数字
        // e.target.value = e.target.value.replace(/[^\d]/g, '');
        // 输入框中只允许输入小数点和数字，小数点后只能跟一位
        e.target.value = e.target.value.match(/^\d*(\.?\d{0,4})/g)[0] || null;
      },
      getOfferDetail() {
        getOfferDetail(this.offerId).then((response) => {
          if (response.code === 200) {
            this.offer = response.result;
            this.eventCapDaily = JSON.parse(response.result.eventCapDaily);
            this.tempEventCapDailyArr.tempEventCapDaily = JSON.parse(response.result.eventCapDaily);
          }
        });
      },
      // del payout info item
      delPayoutInfoItem(index) {
        this.tempEventCapDailyArr.tempEventCapDaily.splice(index, 1);
      },
      // add payout info item
      addPayoutInfoItem() {
        this.tempEventCapDailyArr.tempEventCapDaily.push({
          revenue: '',
          payout: '',
          eventName: '',
          currency: 'USD',
          capDaily: '',
        });
      },
      updatePayoutModalClick() {
        this.payoutVisible = true;
        this.payoutInfo.revenue = this.offer.revenue || 0;
        this.payoutInfo.capDaily = this.offer.capDaily || '';
        this.payoutInfo.payout = this.offer.payout || 0;
        this.payoutInfo.manualCapDaily = this.offer.manualCapDaily || '';
        this.payoutInfo.dailyRevenue = this.offer.dailyRevenue || '';
        this.payoutInfo.clickCapDaily = this.offer.clickCapDaily || '';
        this.payoutInfo.allowDeduct = this.offer.allowDeduct || '';
        this.payoutInfo.clickCapIncrFix = this.offer.clickCapIncrFix || '';
        this.tempEventCapDailyArr.tempEventCapDaily = JSON.parse(this.offer.eventCapDaily);
      },
      updateTrackinglinkClick() {
        const param = {
          offerId: this.offerId,
          sourceTrackingLink: this.sourceTrackinglink,
        };
        updateOfferSource(param).then((response) => {
          if (response.code == 200) {
            this.$message({
              message: 'Update Success',
              type: 'success',
            });
            this.sourceTrackinglink = '';
            this.getOfferDetail();
          } else {
            this.$message.error('Update Error:' + response.message);
          }
        });
      },
      updateSettleEventClick() {
        let param = {
          offerId: this.offerId,
          settleEvent: this.settleEventValue,
        };
        updateOfferSource(param).then((response) => {
          if (response.code == 200) {
            this.$message({
              message: 'Update Success',
              type: 'success',
            });
            this.settleEventValue = '';

            this.getOfferDetail();
          } else {
            this.$message.error('Update Error:' + response.message);
          }
        });
      },
      updatedescModalClick() {
        this.descriptionVisible = true;
        this.descriptionInfo.description = this.offer.description;
        this.descriptionInfo.shortDescription = this.offer.shortDescription;
        this.descriptionInfo.previewLink = this.offer.previewLink;
        this.descriptionInfo.category = this.offer.offerCategory;
        this.descriptionInfo.trafficAllowed = this.offer.trafficAllowed;
        this.descriptionInfo.trafficForbidden = this.offer.trafficForbidden;
        this.descriptionInfo.conversionFlow = this.offer.conversionFlow;
        this.descriptionInfo.classifyType = this.offer.classifyType;
      },
      updateTmpressionClick() {
        // ...
        this.impressionInfo.offerId = this.offer.offerId;
        this.impressionInfo.impressionTracking = this.offer.impressionTracking;
        this.impressionInfo.impressionOpen = this.offer.impressionOpen;
        this.impressionInfo.impressionRatio = this.offer.impressionRatio;
        this.impressionVisible = true;
      },
      updateDescClick() {
        let param = {
          ...cloneDeep(this.descriptionInfo),
        };
        updateOfferDescription(param).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Update Success',
              type: 'success',
            });
            this.offer.description = this.descriptionInfo.description;
            this.offer.shortDescription = this.descriptionInfo.shortDescription;
            this.offer.previewLink = this.descriptionInfo.previewLink;
            this.offer.offerCategory = this.descriptionInfo.category;
            this.offer.trafficAllowed = this.descriptionInfo.trafficAllowed;
            this.offer.trafficForbidden = this.descriptionInfo.trafficForbidden;
            this.offer.conversionFlow = this.descriptionInfo.conversionFlow;
            this.offer.classifyType = this.descriptionInfo.classifyType;
            this.descriptionVisible = false;
          } else {
            this.$message.error(response.message);
          }
        });
      },
      updatePayoutClick(fn1, fn2) {
        const _ts = this;
        // 模态框里有两个form进行双重验证
        this.$refs[fn1].validate((valid1) => {
          if (valid1) {
            _ts.$refs[fn2].validate((valid2) => {
              if (valid2) {
                const param = {
                  ...this.payoutInfo,
                };
                param.eventCapDaily = JSON.stringify(_ts.tempEventCapDailyArr.tempEventCapDaily);
                updateOfferPayout(param).then((response) => {
                  if (response.code === 200) {
                    _ts.$message({
                      message: 'Update Success',
                      type: 'success',
                    });
                    _ts.getOfferDetail();
                    _ts.payoutVisible = false;
                  } else {
                    _ts.$message.error('Update Error:' + response.message);
                  }
                });
              } else {
                return false;
              }
            });
          } else {
            return false;
          }
        });
      },
      updateAsyncInfoClick() {
        const param = {
          offerId: this.offerId,
          ...filterObject(this.asyncSetting),
        };
        updateOfferAsyncInfo(param).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Update Success',
              type: 'success',
            });
            this.getOfferDetail();
            this.settingasyncVisible = false;
          } else {
            this.$message.error('Update Error:' + response.message);
          }
        });
      },
      updateAsyncModalClick() {
        this.settingasyncVisible = true;
        this.asyncSetting.controlType = this.offer.controlType;
        this.asyncSetting.offerName = this.offer.offerName;
        this.asyncSetting.async = this.offer.async;
        this.asyncSetting.important = this.offer.important;
      },
      updateSettingClick() {
        const param = {
          ...cloneDeep(this.settingInfo),
        };
        if (Array.isArray(this.settingInfo.trafficTag)) {
          param.trafficTag = this.settingInfo.trafficTag?.join(',');
        }
        updateOfferSetting(param).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Update Success',
              type: 'success',
            });
            this.getOfferDetail();
            this.settingVisible = false;
          } else {
            this.$message.error('Update Error:' + response.message);
          }
        });
      },
      updateSettingModalClick() {
        // 获取trafficTag 列表
        this.getTrafficTypeList();
        this.settingVisible = true;

        this.settingInfo.platforms = this.offer.platforms || '';
        this.settingInfo.affSubLevel = this.offer.affSubLevel || '';
        this.settingInfo.countries = this.offer.countries || '';
        this.settingInfo.citys = this.offer.citys || '';
        this.settingInfo.carriers = this.offer.carriers || '';
        this.settingInfo.regions = this.offer.regions || '';
        this.settingInfo.downloadType = this.offer.downloadType || '';
        this.settingInfo.trafficTag = this.offer.trafficTag?.split(',') || [];
      },
      onCopy(e) {
        if (e.text) {
          this.$message.success('Copy success');
        }
      },
      onCopyError() {
        this.$message.error('Copy Error');
      },
      updateTimedTaskClick() {
        this.timedTaskVisible = true;
        this.timedTask = {
          offerId: this.offer.offerId,
          status: this.offer.openStatus,
          openTime: this.offer.openTime?.substring(0, 5),
          closeTime: this.offer.closeTime?.substring(0, 5),
        };
      },
      updateTimedTask() {
        const param = {
          ...this.timedTask,
        };
        updateOfferTimedTask(param).then((resp) => {
          if (resp.code === 200) {
            this.$message.success('Update Success');
            this.getOfferDetail();
            this.timedTaskVisible = false;
          } else {
            this.$message.error(resp.message);
          }
        });
      },
      asyncChangeHandle(val) {
        const index = ['app.appsflyer.com', 'app.adjust.com'].findIndex((item) =>
          this.offer.sourceTrackingLink?.includes(item)
        );
        if (val === 'TRUE' && index === -1) {
          this.$message.warning('Async click only available for appsflyer.');
          this.asyncSetting.async = 'FALSE';
          return;
        }
      },
      getTrafficTypeList() {
        getAllTags('offer').then((resp) => {
          if (resp.code === 200) {
            this.trafficTagList = resp.result?.map((item) => ({
              label: item.trafficTag,
              value: item.trafficTag,
              key: item.id,
            }));
          } else {
            this.trafficTagList = [];
          }
        });
      },
      updateSourceModalClick() {
        this.sourceInfoVisible = true;
        this.sourceInfo = {
          offerId: this.offer.offerId,
          campaignName: this.offer.campaignName,
          cap: this.offer.campaignCap,
          status: this.offer.campaignStatus,
        };
      },
      sourceModalCancel() {
        this.$refs.sourceInfoForm.clearValidate();
      },
      impressionModalCancel() {
        this.$refs.impressionForm.clearValidate();
      },
      updateSourceInfoClick() {
        this.$refs.sourceInfoForm.validate((valid) => {
          if (!valid) return;
          const params = { ...this.sourceInfo };
          changeOfferCampaignName(params)
            .then((resp) => {
              if (resp.code === 200) {
                this.$message.success('Change Success');
                this.getOfferDetail();
                this.sourceInfoVisible = false;
              } else {
                this.$message.error(resp.message);
              }
            })
            .catch((error) => {
              this.$message.error(error);
            });
        });
      },
      updateImpressionClick() {
        this.$refs.impressionForm.validate((valid) => {
          if (!valid) return;
          const impressionParams = { ...this.impressionInfo };
          impressionParams.impressionRatio = this.impressionInfo.impressionRatio * 1;
          updateImpression(impressionParams)
            .then((res) => {
              if (res.code === 200) {
                this.$message.success('Change Success');
                this.getOfferDetail();
                this.impressionVisible = false;
              } else {
                this.$message.error(res.message);
              }
            })
            .catch((error) => {
              this.$message.error(error);
            });
        });
      },
      blurFun(index, arr, val) {
        let arrObj = arr.split('.');
        if (arrObj.length == 2) {
          this[arrObj[0]][arrObj[1]][index][val] = this[arrObj[0]][arrObj[1]][index][val].trim();
        }
      },
      updateManagerAndSubRule() {
        this.upManagerAndSubRule.ruleId = this.offer.ruleId;
        this.upManagerAndSubRule.accountId = this.offer.accountId;
        this.upManagerAndSubRuleVisible = true;
      },
      // 获取manager列表
      managerList() {
        getPermissionAllList().then((res) => {
          console.log(res);
          this.option.manager = res.result;
        });
      },
      // 获取subrule列表
      subruleList() {
        getReportSubruleList({ status: 1 }).then((res) => {
          this.option.rule = res.result;
        });
      },
      // 保存manager-subrue
      upManagerAndSubRuleClick() {
        // let _this = this;
        // let manager = this.option.manager.filter(function(e) {
        //   return e.accountId == _this.upManagerAndSubRule.accountId;
        // });
        const query = {
          offerId: this.offer.offerId,
          accountId: this.upManagerAndSubRule.accountId,
          ruleId: this.upManagerAndSubRule.ruleId,
          // manager: manager[0].username,
        };
        updateOfferManagerRule(query)
          .then((res) => {
            if (res.code !== 200 && res.success == false) {
              this.$message.error(res.message);
            } else {
              console.log(res);
              this.getOfferDetail();
              this.$message({
                message: '更新成功',
                type: 'success',
              });
            }
            this.upManagerAndSubRuleVisible = false;
          })
          .catch((error) => {
            this.$message.error('更新失败！' + error.toString());
            this.upManagerAndSubRuleVisible = false;
          });
      },
      //
      getImgsList() {
        fetchCreativesList({ offerId: this.offerId }).then((res) => {
          console.log(res);
          this.creativiesInfo = [];
          if (res.result) {
            for (var i = 0; i < res.result.length; i++) {
              this.creativiesInfo.push({
                name: res.result[i].creativeUrl,
                url: res.result[i].creativeUrl,
                ...res.result[i],
              });
            }
          } else {
            this.creativiesInfo = [];
          }
        });
      },
      beforeRemove(file) {
        console.log(file);
        if (file.id && file.creativeUrl) {
          handleCreativesDelete({ id: file.id, creativeUrl: file.creativeUrl })
            .then((res) => {
              console.log(res);
              this.$message({
                type: 'success',
                message: '删除成功!',
              });
              this.getImgsList();
              return true;
            })
            .catch((e) => {
              this.$message.error(e);
            });
        }
      },
      // 上传中
      beforeUpload(file) {
        const imgType = file.type === 'image/jpeg' || file.type === 'image/png';
        const isLt1M = file.size / 1024 / 1024 < 1;
        if (!imgType) {
          this.$message.error('上传头像图片只能是 JPG / PNG 格式!');
          return false;
        }
        if (!isLt1M) {
          this.$message.error('上传头像图片大小不能超过1M!');
          return false;
        }
        this.cretiviesInfoLoading = true;
      },
      // 上传成功
      uploadSuccess(response) {
        console.log(response);
        const uploadImgDataArr = [
          {
            creativeUrl: response.result.creativeUrl,
            size: response.result.size,
            offerId: this.offerId,
          },
        ];
        this.uploadImgData(uploadImgDataArr);
      },
      // 保存图片数据
      uploadImgData(data) {
        handleCreativesAddBatch(data).then((res) => {
          console.log(res);
          if (res.code === 200) {
            this.cretiviesInfoLoading = false;
            this.getImgsList();
          }
        });
      },
      // 删除图片
      handleRemove(file) {
        console.log(file);
        // for (var i = 0; i < this.creativiesInfo.length; i++) {
        //   if (this.creativiesInfo[i].creativeUrl === file.response.result.creativeUrl) {
        //     this.creativiesInfo.splice(i, 1);
        //   }
        // }
      },
      updateDirectLinkStatus() {
        console.log('update direct link');
        const param = {
          offerId: this.offerId,
          isDirectLink: this.offer.isDirectLink,
        };
        updateOfferIsDirectLink(param).then((response) => {
          if (response.code == 200) {
            this.$message({
              message: 'Update Success',
              type: 'success',
            });
            this.getOfferDetail();
          } else {
            this.$message.error('Update Error:' + response.message);
          }
        });
      },
      updateadvancedProxy() {
        console.log('dzk');
        const param = {
          id: this.offer.offerId,
          advancedProxy: this.offer.advancedProxy,
        };
        updateOfferAdvancedProxy(param).then((response) => {
          if (response.code == 200) {
            this.$message({
              message: 'Update Success',
              type: 'success',
            });
            this.getOfferDetail();
          } else {
            this.$message.error('Update Error:' + response.message);
          }
        });
      },
    },
  };
</script>
<style>
  .offerInfo .el-card__header,
  .sourceInfo .el-card__header,
  .payoutInfo .el-card__header,
  .settingInfo .el-card__header,
  .Description .el-card__header {
    padding: 10px;
  }
</style>
<style lang="scss" scoped>
  .detail_title {
    font-size: 16px;
  }
  .detail_row {
    margin-bottom: 10px;
  }
  .detail_button {
    float: right;
  }
  .detail_item {
    margin-left: 20px;
    color: #337ab7;
    font-size: 16px;

    span {
      color: #303133;
      font-size: 14px;
      vertical-align: middle;
      word-wrap: break-word;
      word-break: break-all;
      white-space: normal;
    }
    .line-overflow {
      display: inline-block;
      max-width: 350px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
</style>
